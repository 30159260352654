.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logoSize {
  height: 46px;
  width: 200.4px;
}

.inputButton {
  color: invert;
  height: 25px;
  width: 175px;
}

.sendButton {
  border-color: #3c5f8f;
  border-width: 4px;
  border-radius: 25px;
  color: #ffffff;
  background-color: #3c5f8f;
  height: 45px;
  width: 175px;
  
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* image sizes */
.imageSizeHome {
  margin-top: 19.8%;
  object-fit: cover;
  filter:brightness(.5);
  height: 150%;
  width: 100%;
}

.imageSize2 {
  object-fit:cover;
  height: 50%;
  width: 50%;
}

.imageSize3 {
  margin-top: -.15%;
  object-fit: cover;
  filter:brightness(.35);
  height: 1000px;
  width: 100%;
}

.dmnPicSize{
  margin-top: 8%;
  width: 104px;
  height: 90px;
  margin-left: 32%;
}

.aboutImage{
  border-radius: 20px;
  transform: translate(15%, 12%);
  object-fit:cover;
  height: 700px;
  width: 1000px
}


/* Text */
.centeredTextCards{
  align-items: center;
}

.centeredTitleText {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #275ec4;
}

.centeredBodyText {
  text-align: center;
  font-weight: 500;
  color: #000000;
}

.centeredTextWhite {
  text-align: center;
  font-size: 50px;
  word-spacing: 3px;
  font-weight: 800;
  color: #ffffff;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTextColored {
  text-align: center;
  font-size: 50px;
  word-spacing: 3px;
  font-weight: 800;
  color: #3d7599;
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topText{
  display: flex;
  justify-content: center;
  transform: translateY(75px);
  color: #203c70;
  font-weight: 650;
  font-size: 45px;
}


/* buttons */
.btn-primary {
  border-radius: 8px;
  width:175px;
  height: 50px;
  background-color:#1878ff;
  transition: all .3s ease-in-out;
  &:hover {
    transition: all .3s ease-in-out;
    background: #ffff;
    color: #3d7599;
    border-radius: 15px;
  }
  &.active {
    color: #000000;
}}

.homeButton2 {
  border-width: 2px;
  border-radius: 8px;
  color: #ffffff;
  border-color: #ffffff;
  width:175px;
  height: 50px;
  transition: all .3s ease-in-out;
  &:hover {
    transition: all .3s ease-in-out;
    background: #ffffff4b;
    color: #ffffff;
    border-radius: 20px;
    border-color: #ffff;
    border-width: 3px;
  }
  &.active {
    color: #000000;
}}

.avgGradiantBtn{
    background-image: linear-gradient(45deg, #275ec4,rgb(36, 46, 71));  
    &:hover {
    transition: all .2 ease-in-out;
    border-color: #94b1e6;
    border-width: 3px;
    background-image: linear-gradient(45deg, #3269d1,rgb(64, 76, 109));  
    color: #ffffff;
    border-radius: 20px;
  }
}


/* Paging/Formatting */
.contactForm {
  width: 75%;
}

.formstrue{
 
  flex-direction: column;
  width: 100%;
  height: 500px;
}
.forms1{
  display: flex;
  justify-content: center;
  
  width: 100%;
  height: 500px;
}
.mb-3{
  margin-top: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.brightness{
  filter: brightness(.5);
}

.scrollAbout{
  min-height:min-content;
}

.aboutService{
  transform: translate(50px,50px);
}

.center{
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.collumn {
  flex-direction: column;
	display: flex;
	justify-content: space-evenly;
	align-items: center ;
	height: 75vh;
}

.absolute{
  align-items: center;
  position: absolute;
  top: 555px;
  left: 50%;
  transform: translate(-50%, -50%)
}


/* cards */
.cardscolumn {
  display: flex;
  flex-direction: column;
}

.cardsrow {
  margin-top: 5%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}
.cardAbout{
  border-color: rgb(49, 115, 177);
  border-width: 6px;
  transform: translate(220%,-100%);
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
}

.cardReport{
  border-color: rgb(49, 115, 177);
  border-width: 6px;
  transform: translate(100%,-75%);
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
}

.cardIntelligence{
  border-color: rgb(49, 115, 177);
  border-width: 6px;
  transform: translate(250%,-175%);
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
}

.cardAuto{
  border-color: rgb(49, 115, 177);
  border-width: 6px;
  transform: translate(400%,-275%);
  position: absolute;
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
}

.cardStyle{
  border-color: rgb(49, 115, 177);
  border-width: 6px;
  border-radius: 20px;
  font-size: large;
  font-weight: 500;
}